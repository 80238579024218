import React from 'react';

import { Text } from 'components';
import styled from 'styled-components';

import { tablet } from 'styles/breakpoints';

import Team from '../../assets/images/teamMembers/Team.png';
import Quote from '../../assets/icons/symbol.png';
import Layout from 'layouts/Layout';

const Story = () => (
  <Layout
    seoTitle="Our Story"
    seoDescription="See how it all started"
    seoName="Our Story"
  >
    <Container>
      <ImageContainer>
        <StyledImage src={Team} />
      </ImageContainer>
      <TextContainer>
        <TextWrap>
          <Title>
            Take control of your <strong>productivity</strong>
          </Title>
          <SubTitle>
            We believe that everyone should have access to a comprehensive tool
            that aids in eliminating procrastination or enhancing overall
            efficiency, based on intuitive technology and personalized tracking.
          </SubTitle>
        </TextWrap>
      </TextContainer>
    </Container>
    <StartedContainer>
      <StartedWrapper>
        <Entry>
          <EntryTitle>How it started</EntryTitle>
          <EntryHeading>From the beginning...</EntryHeading>
          <EntryText>
            Our journey took off in 2004 when we first crossed paths. Jokubas
            and I (Kazys, hello there!) established a deep friendship from an
            early age. Throughout our high school years, we were particularly
            drawn to psychology and productivity; you could have pegged us as
            kind of nerds back then, forever immersed in books and academic
            articles. Naturally, this interest rapidly evolved into friendly
            rivalry, as we began to scrutinize our behaviors and how diverse
            factors impacted our responses to various circumstances: our task
            completion rate, decision-making, mood, even procrastination – you
            name it. It was a pure curiosity that morphed into a fun experiment
            for us.
          </EntryText>
          <EntryText>
            The quantity of tools and time we invested in tracking these
            elements was immense, ranging from note-taking to exhaustive 50-page
            excel spreadsheets. Although our initial efforts bore some fruit,
            our tracking was not systematic enough, we&apos;d forget to document
            something or remind each other about a specific task, making us
            realize that we essentially needed a centralized platform. By
            observing each other and constantly exchanging tips on
            self-improvement, we discerned that mere advice was not sufficient.
            The optimal and quickest path to breaking unproductive habits and
            boosting overall efficiency was through a detailed self-analysis and
            comprehensive statistics.
          </EntryText>
          <EntryHeading>Exploring the potential</EntryHeading>
          <EntryText>
            This idea blossomed as we both started our studies in psychology in
            2016 and discovered a vast demand for such a unified platform. We
            realized that many people were employing similar productivity
            tracking techniques without significant results. Seeing some of our
            loved ones struggle with procrastination, and being self-improvement
            enthusiasts ourselves, our passion for research persisted. With a
            shared zeal for productivity enhancement, we joined several
            psychology organizations and attended numerous conferences, studying
            the field of productivity and mental health from every perspective.
            Sure, it was a bit laborious, but ultimately – completely worth it.
          </EntryText>
          <EntryText>
            That&apos;s when we uncovered that we, like everyone else, are
            persistently striving to reach our maximum potential, either
            cognitively or behaviorally, but may encounter hurdles without the
            right tools.
          </EntryText>
          <EntryText>
            Everyone has habits that frustrate them, diminish their energy,
            productivity, or otherwise impact their overall efficiency. This set
            us thinking:
          </EntryText>
          <StyledBlockquote>
            <BlockquoteText>
              There has to be a systematic method to thoroughly understand
              ourselves for improvement while also aiding as many people as
              possible, be it overcoming procrastination or enhancing
              self-efficacy.
            </BlockquoteText>
          </StyledBlockquote>

          <EntryText>
            Soon, we discerned that this was a common struggle among countless
            individuals. We yearned for a tool that could assist everyone,
            regardless of their background or situation. After connecting the
            dots, the solution was crystal clear.
          </EntryText>
          <EntryText>
            We saw enormous potential in such a concept, an app that could form
            the foundation for productivity tracking and self-improvement. We
            knew it would necessitate time and research, but we truly believed
            in it and were prepared to make the shift. And that’s precisely what
            we did.
          </EntryText>
          <EntryHeading>Making a difference</EntryHeading>
          <EntryText>
            Taking the reins, we embarked on our journey towards Brainway that
            very year. We had some entrepreneurial experience, but a lengthy
            road lay ahead. We started from square one, reverting to our old
            routines of note-taking and excel spreadsheets, seeking advice from
            our friends, peers, and psychology professionals.
          </EntryText>
          <EntryText>
            Once we grew more confident about our app, we sought someone who
            could offer further assistance from a business perspective,
            possessing expertise in analyzing user demand.
          </EntryText>
          <EntryText>
            As a result, our team welcomed a third member Tomas – a skillful
            marketing expert and data analyst. Consequently, the testing and
            development progressed, meticulously crafting and examining various
            user cases so that our app would be beneficial to everyone: from
            high performers and habit trackers to self-analyzers and those
            grappling with procrastination.
          </EntryText>
        </Entry>
      </StartedWrapper>
    </StartedContainer>
    <ProudContainer>
      <ProudWrap>
        <ProudEntry>
          <ProudTitle>The outcome we&apos;re proud of</ProudTitle>
          <ProudText>
            What started as a hobby and an innocent childhood curiosity has now
            morphed into a comprehensive tool to assist individuals. After many
            years of tireless work and research, we&apos;re delighted to
            introduce you: Brainway.
          </ProudText>
          <ProudText>
            We aim to assist those struggling with procrastination or those who
            simply aspire to boost their productivity, and we&apos;re convinced
            that Brainway is just what you need to achieve that.
          </ProudText>
          <ProudText>
            Regain control of your productivity and commence your journey to a
            more focused, efficient you.
          </ProudText>
        </ProudEntry>
      </ProudWrap>
    </ProudContainer>
  </Layout>
);

export default Story;

const BlockquoteText = styled(Text)`
  letter-spacing: -0.024em;
  font-family: Inter;
  font-size: 1.25rem;
  @media ${tablet} {
    font-size: 1.125rem;
  }
`;

const StyledBlockquote = styled.blockquote`
  background: url(${Quote}) no-repeat 0 24px;
  background-size: 48px auto;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.036em;
  line-height: 140%;
  margin: 32px 0 22px;
  padding: 24px 0 12px 80px;
`;

const ProudText = styled(Text)`
  margin-bottom: 22px;
  color: #fff;
  font-family: Inter;
  font-weight: 300;
`;

const ProudTitle = styled(Text)`
  color: #fff;
  font-size: 28px;
  font-weight: 500;
  letter-spacing: -0.036em;
  line-height: 140%;
  margin-bottom: 32px;
  font-family: Inter;
  @media ${tablet} {
    font-size: 1.5rem;
    margin-bottom: 24px;
  }
`;

const ProudEntry = styled.div`
  margin: auto;
  max-width: 768px;
  line-height: 138%;
`;

const ProudWrap = styled.div`
  margin: auto;
  max-width: 100%;
  padding: 0 32px;
  width: 1204px;
  @media ${tablet} {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const ProudContainer = styled.div`
  background: ${({ theme }) => theme.colors.secondaryDark};
  color: ${({ theme }) => theme.colors.light0};
  padding: 4.5rem 1rem;
  @media ${tablet} {
    padding: 3rem 1rem;
  }
`;

const EntryText = styled(Text)`
  margin-bottom: 22px;
  font-family: Inter;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.dark80};
`;

const EntryHeading = styled(Text)`
  color: ${({ theme }) => theme.colors.dark100};
  font-size: 28px;
  font-weight: 500;
  letter-spacing: -0.036em;
  line-height: 140%;
  margin-bottom: 32px;
  margin-top: 48px;
  @media ${tablet} {
    font-size: 24px;
    margin-bottom: 24px;
    margin-top: 32px;
  }
`;

const EntryTitle = styled(Text)`
  font-size: 38px;
  font-weight: 600;
  letter-spacing: -0.036em;
  line-height: 140%;
  margin-bottom: 32px;
  color: #222;
  font-family: Inter;
  @media ${tablet} {
    font-size: 1.75rem;
    margin-bottom: 24px;
  }
`;

const Entry = styled.div`
  line-height: 150%;
  margin: auto;
  max-width: 768px;
`;

const StartedWrapper = styled.div`
  margin: auto;
  max-width: 100%;
  padding: 0 32px;
  width: 1204px;
  @media ${tablet} {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const StartedContainer = styled.div`
  padding-bottom: 42px;
  padding-top: 42px;
`;

const Title = styled(Text)`
  font-size: 3rem;
  color: #1c1c28;
  display: flex;
  flex-direction: column;
  font-size: 54px;
  font-size: 48px;
  font-weight: 400;
  letter-spacing: -0.036em;
  line-height: 140%;
  line-height: 130%;
  margin-bottom: 24px;
  @media ${tablet} {
    font-size: 1.625rem;
  }
`;

const SubTitle = styled(Text)`
  font-size: 1.125rem;
  font-family: Inter;
  color: #1c1c28;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: -0.024em;
  line-height: 150%;
  @media ${tablet} {
    font-size: 1rem;
  }
`;

const TextWrap = styled.div`
  width: 46%;
  @media ${tablet} {
    width: unset;
  }
`;

const TextContainer = styled.div`
  margin: auto;
  max-width: 100%;
  padding: 0 32px;
  width: 1204px;
  @media ${tablet} {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const StyledImage = styled.img`
  display: block;
  margin-left: auto;
  max-height: 100%;
  min-width: 768px;
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%;
  @media ${tablet} {
    min-width: unset;
  }
`;

const ImageContainer = styled.div`
  bottom: 0;
  padding-bottom: 1.5rem;
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
  @media ${tablet} {
    margin-bottom: 1.5rem;
    margin: 0;
    position: static;
    width: auto;
  }
`;

const Container = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 78px;
  min-height: 600px;
  position: relative;
  @media ${tablet} {
    display: block;
    min-height: auto;
    margin-bottom: 1.5rem;
  }
`;
